
function loader(file = null) {
  const prefix = '/data/';
  let next = file || 'newest.json';
  const load = (first = false) => {
    if (first === true) {
      next = 'newest.json';
    }
    if (file !== null && next === null) {
      return null;
    }
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.onload = () => {
        const thisFile = next;
        if (!file) {
          next = req.response.nextTopic || null;
          if (next !== null && next.endsWith('.json')) {
            next = next.substr(0, next.length - 5);
          }
        } else if (file.endsWith('.json')) {
          // eslint-disable-next-line no-param-reassign
          file += '.json';
        }
        if (req.response.loadNextTopic && file === null) {
          load().then(resolve).catch(reject);
        } else {
          resolve({ response: req.response, nextFile: next, thisFile: file || thisFile });
        }
      };
      req.onerror = reject;
      req.onabort = reject;
      let url = prefix + next;
      if (!url.endsWith('.json')) {
        url += '.json';
      }
      req.open('GET', url);
      if (url.endsWith('newest.json')) {
        req.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');
        req.setRequestHeader('Expires', 'Thu, 1 Jan 1970 00:00:00 GMT');
        req.setRequestHeader('Pragma', 'no-cache');
      } else {
        req.setRequestHeader('Cache-Control', 'max-stale');
      }
      req.responseType = 'json';
      req.send();
    });
  };
  return load;
}

export default loader();
export { loader };
