<template>
  <div class="overview w-full relative">
    <h1 v-if="showDescription" class="instruction">Task: get an overview of a given news topic</h1>
    <div class="instruction-box" v-if="showDescription">
      <p class="instruction">
        Below you see an overview showing you a single news topic and multiple news articles
        reporting on it. Please familiarize yourself with the topic and its
        articles. Also, understand additional information that is presented to you below, if any.
      </p>
      <p class="instruction">
        It is crucial that you get at least an understanding of the topic's main perspectives
        present in the news coverage shown below.
      </p>
      <p class="instruction">
        Once finished, click the button on the bottom of the page to continue the survey.
      </p>
    </div>
    <div v-for="([file, topic, processingTimestamp], i) in topics" :key="i">
      <h1
        v-if="i == 0 || topics[i-1][2] !== processingTimestamp"
        class="mt-4"
      >
        News from {{ processingTimestamp }}
      </h1>
      <component
        v-for="documentSet in topic" :key="documentSet.identifier"
        :small-preview="true"
        :class="cls"
        v-bind:is="$store.getters.overviewComponent"
        :document-set="documentSet.documentSet"
        :identifier="documentSet.identifier"
        :base-link="`/article/${documentSet.date}/${documentSet.identifier}/`"
        :file="file"
      />
    </div>
    <span v-if="loading"><strong>Loading...</strong></span>
    <span v-else-if="noMoreTopics" class="no-more-topics mb-2">
      Sorry, we currently do not have more topics available
    </span>
    <span v-else class="load-next" @click="loadNext" ref="nextTopicLoader">
      Click here to load the next topic
    </span>
  </div>
</template>

<script>
import _ from 'lodash';
import DocumentParser from '@/libraries/DocumentParser.js';
import topicLoader from '@/libraries/TopicLoader';

const overview = {
  name: 'overview',
  props: [
    'file',
  ],
  data() {
    return {
      topics: [],
      loading: false,
      noMoreTopics: false,
    };
  },
  computed: {
    showDescription() {
      return process.env.VUE_APP_SHOW_NEWS_OVERVIEW_DESCRIPTION === 'true';
    },
    cls() {
      if (this.$store.state.configuration.overviewView) {
        return `overview-${this.$store.state.configuration.overviewView.toLowerCase()}`;
      }
      return '';
    },
  },
  methods: {
    loadNext(first = false) {
      const loader = topicLoader(first);
      if (loader === null) {
        this.noMoreTopics = true;
      } else {
        this.loading = true;
        loader.then(({ response, thisFile, nextFile }) => {
          this.topics.push([thisFile, DocumentParser.parseDocumentSets(
            response.documentSets,
            this.$store.state.configuration,
            response.randomSeedString,
          ), (new Date(response.processingTimestamp)).toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          })]);
          this.loading = false;
          if (nextFile === null) {
            this.noMoreTopics = true;
          }
        }).catch((e) => { alert(`Could not load new topic: ${e}`); this.loading = false; console.error(e); });
      }
    },
    loaderVisible() {
      if (!this.$refs.nextTopicLoader) { return false; }
      const {
        top, left, bottom, right,
      } = this.$refs.nextTopicLoader.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      return ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight))
          && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
    },
    checkLoadNext: _.debounce(function () {
      if (this.loaderVisible()) {
        this.loadNext();
      }
      this.checkLoadNext();
    }, 100),
  },
  mounted() {
    this.loadNext(true);
    document.addEventListener('scroll', this.checkLoadNext);
    this.checkLoadNext();
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.checkLoadNext);
  },
};
export default overview;
</script>
