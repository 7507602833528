<template>
  <div class="home">
    <DynamicOverview />
  </div>
</template>

<script>
import DynamicOverview from '@/components/DynamicOverview.vue';

export default {
  name: 'home',
  components: { DynamicOverview },
};
</script>
